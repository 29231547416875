import React from 'react';
import Helmet from 'react-helmet';

import { Box } from '../components/Core';
import Text from '../components/Text';
import { TEXT_STYLES } from '../components/Text/constants';

const ObfuscatedPage: FCWithClassName = () => {
  const crawlingBotMsg = 'Nasty crawling bot...';
  return (
    <Box className="relative z-0 min-h-screen p-4 lg:p-10">
      <Helmet>
        <meta name="robots" content="none" />
      </Helmet>
      <Text variant={TEXT_STYLES.H2}>{crawlingBotMsg}</Text>
    </Box>
  );
};

export default ObfuscatedPage;
